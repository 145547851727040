<div fxLayout="column" fxLayoutAlign="space-between">
  <ul class="link-text">
    <li *ngFor="let page of links$ | async">{{ page.route }}</li>
  </ul>
  <div fxHide [fxShow.gt-sm]=true class="hero-banner" fxLayout="center" fxLayout="column">
    <h1 class="hero-text" fxLayout="row" fxLayoutAlign="center">Welcome to</h1>
    <h1 class="hero-text" fxLayout="row" fxLayoutAlign="center">San Sebastian</h1>
    <h1 class="hero-text" fxLayout="row" fxLayoutAlign="center">Equestrian Center</h1>
  </div>
  <div fxHide [fxShow.lt-md]=true class="small-hero-banner" fxLayout="center" fxLayout="column">
    <h1 fxLayout="row" fxLayoutAlign="center">Welcome to San Sebastian Equestrian Center</h1>
  </div>
<!--     <div fxHide [fxShow.gt-sm]=true fxLayout="column" class="carousel-border"> -->
  <div fxLayout="column" class="carousel-border">
    <ng-carousel
      [config]="config"
      (itemIndexChange)="setItemIndex($event)"
      class="carousel-block">
      <ng-template ngCarouselSlide let-item let-active="isActive">
        <div fxLayoutAlign="center" [matTooltip]=item.title matTooltipPosition="above" [class.active]="active" [style.background-image]="item.src | safeHtml" class="carousel-slide">
<!--           <h2>
            {{item.title}}
          </h2> -->
  <!--         <button mat-raised-button ngCarouselPreventGhostClick color="primary">
            Primary action
          </button>
          <button mat-button ngCarouselPreventGhostClick color="primary">
            Secondary action
          </button> -->
        </div>
      </ng-template>
    </ng-carousel>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button *ngFor="let item of config.items; index as i;" [class.active]="i === itemIndex" (click)="goTo(i)"
        class="bullet-button" mat-icon-button>
         {{i + 1}}
      </button>
    </div>
  </div>
</div>
